import styled from '@emotion/styled'

import { Container as ContainerComponent } from '@/legacy/components/Container'
import { BodyM, Display } from '@/legacy/components/Typography'
import { CONFIG } from '@/ui/shared/styles'

const MainContainer = styled.div<Partial<Record<'primary' | 'secondary', string>>>`
  background-color: ${({ primary }) => primary};

  ${CONFIG.media.mobileLg} {
    background-image: unset;
    background-color: ${({ primary }) => primary};
    z-index: -2;
  }
`
const Container = styled(ContainerComponent)`
  padding: 0;
  .ecs-container {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-areas: 'heading presentation';
    min-height: 28rem;

    ${CONFIG.media.mobileLg} {
      padding: 0;
      height: 30.75rem;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 0;
      grid-template-areas:
        'presentation'
        'heading';
    }
  }
`

const Box = styled.div`
  grid-area: heading;
  margin: auto 0;
  max-width: 29rem;
  padding: 0 5rem 0 0;

  ${CONFIG.media.mobileLg} {
    margin: auto 1rem;
    width: calc(100% - 2rem);
    position: relative;
    max-height: 12.5rem;
    max-width: 58rem;
    background-color: #fbedf4;
    border-radius: 1.25rem;
    padding: 1rem 1.5rem;
    top: -13.5rem;
    z-index: 1;
  }
`

const Title = styled(Display)`
  ${CONFIG.media.lg} {
    color: rgba(var(--theme-snow));
  }

  ${CONFIG.media.mobileLg} {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 0.5rem;
  }

  &.eu-bm-4 {
    margin-bottom: 2.5rem;
  }
`

const Subtitle = styled(BodyM)`
  font-size: 1.125rem;
  margin-bottom: 2.5rem;

  ${CONFIG.media.lg} {
    color: rgba(var(--theme-snow));
  }

  ${CONFIG.media.mobileLg} {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1rem;
  }
`

const CtaWrapper = styled.div`
  width: 100%;
  max-width: 20rem;

  .ea-button {
    width: 100%;

    ${CONFIG.media.lg} {
      background-color: rgba(var(--theme-snow));
      color: rgba(var(--theme-primary));

      &:hover {
        opacity: 0.9;
      }
    }
  }
  ${CONFIG.media.mobileLg} {
    max-width: 100%;
  }
`

const Presentation = styled.div`
  grid-area: presentation;
  margin: auto;
`

export const HeroStyles = {
  MainContainer,
  Container,
  Box,
  Title,
  Subtitle,
  CtaWrapper,
  Presentation,
}
