/* eslint-disable jsx-a11y/alt-text */
import { Image } from '@/legacy/components/Image'

import { HeroStyles } from './styles'
import type { PresentationProps } from './types'

export default function Presentation({ isMobile, images, ...props }: PresentationProps) {
  const hasImage = images?.desktop?.url || images?.mobile?.url
  if (!hasImage) return
  return (
    <HeroStyles.Presentation>
      {isMobile && images.mobile.url && (
        <Image width={360} height={476} data={{ ...images.mobile }} priority {...props} />
      )}
      {!isMobile && images.desktop.url && (
        <Image width={374} height={370} data={{ ...images.desktop }} priority {...props} />
      )}
    </HeroStyles.Presentation>
  )
}
