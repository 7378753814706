import type { BackgroundColor } from '@/legacy/components/Container'

export const getBackgroundColor = ({ color, hex }: { color: BackgroundColor; hex?: string }) => {
  const COLOR_MAPPER = {
    primary: 'rgba(var(--theme-primary))',
    snow: 'rgba(var(--theme-snow))',
    gray: 'rgba(var(--theme-surface-palegray))',
    palegray: 'rgba(var(--theme-palegray))',
    accent: 'rgba(var(--theme-accent))',
    custom: hex || 'rgba(var(--theme-snow))',
  }
  return COLOR_MAPPER[color]
}
