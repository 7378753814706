import cx from 'classnames'

import { builderLightText } from '@/legacy/blocks/utils'
import { Button } from '@/legacy/components/Button'
import type { BackgroundColor, ContainerProps } from '@/legacy/components/Container'
import { extractCustomButtonEvent } from '@/legacy/core/analytics/utils/extract-custom-event-props'
import { decodeURL } from '@/legacy/core/utils/dom/decodeURL'
import { useIsMobile } from '@/legacy/hooks/useIsMobile'

import { useDevice } from '@/ui/shared/providers'
import { CONFIG } from '@/ui/shared/styles'

import Presentation from './presentation'
import { HeroStyles } from './styles'

import { getBackgroundColor } from './utils'

import type { HeroProps } from '.'

export default function GenericHero({
  id,
  title,
  showSubtitle = true,
  subtitle,
  hasButton = false,
  space,
  primaryBackground,
  primaryBackgroundColorHex,
  secondaryBackground,
  secondaryBackgroundColorHex,
  colorModifier,
  button,
  buttonLabel,
  buttonType,
  buttonValue,
  buttonAriaLabel,
  analytics,
  images,
}: HeroProps) {
  const deviceHasMobileScreen = useIsMobile(CONFIG.maxWidths.md)
  const isMobile = useDevice() !== 'desktop' || deviceHasMobileScreen
  const buttonProps = {
    buttonLabel: buttonLabel || button?.label,
    buttonType: buttonType || button?.action?.type,
    buttonValue: buttonValue || button?.action?.value,
    buttonAriaLabel: buttonAriaLabel || button?.label,
  }
  const containerProps: ContainerProps = {
    spaceOut: space,
    id,
  }

  const backgrounds = {
    primary: { color: primaryBackground as BackgroundColor, hex: primaryBackgroundColorHex },
    secondary: { color: secondaryBackground as BackgroundColor, hex: secondaryBackgroundColorHex },
  }

  const backgroundColorProps = {
    primary: primaryBackground && getBackgroundColor({ ...backgrounds.primary }),
    secondary: secondaryBackground && getBackgroundColor({ ...backgrounds.secondary }),
  }

  const presentationProps = { isMobile, images }
  const lightText = builderLightText(colorModifier)
  const customButtonEvent = extractCustomButtonEvent(analytics)
  const shouldShowSubtitle = subtitle && subtitle.text && showSubtitle

  return (
    <HeroStyles.MainContainer {...backgroundColorProps}>
      <HeroStyles.Container {...containerProps}>
        <HeroStyles.Box>
          <HeroStyles.Title
            className={cx(`eu-sm-1`, { 'eu-bm-4': !shouldShowSubtitle })}
            tag={title?.tag || 'h1'}
          >
            {title?.text}
          </HeroStyles.Title>
          {shouldShowSubtitle && (
            <HeroStyles.Subtitle dark="high" tag={subtitle?.tag}>
              {subtitle?.text}
            </HeroStyles.Subtitle>
          )}

          {hasButton && buttonProps && (
            <HeroStyles.CtaWrapper>
              <Button
                event={customButtonEvent}
                action={buttonProps.buttonType}
                value={decodeURL(buttonProps.buttonValue)}
                size="lg"
                modifier={lightText ? 'solid-invert' : 'solid'}
                aria-label={buttonProps.buttonAriaLabel}
                aria-hidden={!hasButton}
              >
                {buttonProps.buttonLabel}
              </Button>
            </HeroStyles.CtaWrapper>
          )}
        </HeroStyles.Box>

        <Presentation {...presentationProps} aria-hidden />
      </HeroStyles.Container>
    </HeroStyles.MainContainer>
  )
}
